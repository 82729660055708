<template>
	<div class="flex">
		<section class="content-main">
			<el-form :model="form" :rules="formRules" ref="form" label-width="140px">
				<el-form-item label="店铺ID" prop="store_id">
					<el-input v-model.trim="form.store_id" class="short-width" clearable></el-input>
				</el-form-item>
				<el-form-item label="服务类型" prop="server_type">
					<el-select v-model="form.server_type" placeholder="请选择付款类型" clearable class="short-width">
						<el-option v-for="item in serverType" :key="item.label" :label="item.value" :value="item.label"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="服务周期" prop="time">
					<el-date-picker v-model="form.time" type="daterange" :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right" value-format="timestamp" :default-time="['00:00:00', '23:59:59']"></el-date-picker>
				</el-form-item>
				<el-form-item label="支付金额" prop="pay_amount">
					<el-input v-model="form.pay_amount" class="short-width" clearable></el-input>
				</el-form-item>
				<el-form-item label="支付人" prop="payer">
					<el-input v-model="form.payer" class="short-width" clearable></el-input>
				</el-form-item>
				<el-form-item label="支付类型" prop="pay_company">
					<el-select v-model="form.pay_company" placeholder="请选择付款类型" clearable class="short-width">
						<el-option v-for="item in payType" :key="item.label" :label="item.value" :value="item.label"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="支付状态" prop="order_status">
					<el-select v-model="form.order_status" placeholder="请选择付款类型" clearable class="short-width">
						<el-option v-for="item in orderStatus" :key="item.label" :label="item.value" :value="item.label"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if="form.order_status == 2 || form.order_status== 3" label="支付流水号" prop="pay_order_id">
					<el-input v-model="form.pay_order_id" class="short-width" clearable></el-input>
				</el-form-item>				
				<el-form-item label="备注">
					<el-input v-model="form.remark" class="short-width" clearable></el-input>
				</el-form-item>
				<el-form-item label=" ">
					<el-button type="primary" @click.native="saveSubmit" icon="el-icon-circle-check">创建订单</el-button>
				</el-form-item>
			</el-form>
		</section>
		<div class="flex1">
			<img v-if="big_img" :src="big_img" class="avatar1">
		</div>
	</div>
</template>
	<script>
	import {pickerOptions,orderStatus,payType,serverType} from '@/utils/const';

	console.log(payType)
		import { createOrderByHand } from '../../api/api';
		export default {
			data() {
				return {
					pickerOptions,
					orderStatus,
					payType,
					serverType,
					//编辑ID
					aID: this.$route.query.id,
					//表单验证规则
					formRules: {
						store_id: [
							{ required: true, message: '请输入店铺ID', trigger: 'blur' }
						],						   
						server_type: [
							{ required: true, message: '请选择服务类型', trigger: 'blur' }
						],
						pay_amount: [
							{ required: true, message: '请输入支付金额', trigger: 'blur' }
						],
						payer: [
							{ required: true, message: '请输入支付人姓名', trigger: 'blur' }
						],
						pay_company: [
							{ required: true, message: '请选择支付类型', trigger: 'blur' }
						],
						order_status: [
							{ required: true, message: '请选择支付状态', trigger: 'blur' }
						],
						time: [
							{ required: true, type:'array', message: '请选择服务周期', trigger: 'change' }
						]
					},
					//新增界面数据
					form: {
						store_id: '',
						server_type: null,
						time:[],
						cycle_start:'',
						cycle_end:'',
						pay_amount: '',
						payer: '',
						pay_company: 2,
						order_status: 1,
						pay_order_id:'',
						remark: '',
					},
					provinceList: [],
					cityList: [],
					big_img: ''
				}
			},
			watch:{
				'form.time': function(newValue,oldVale){
					this.form.cycle_start = this.form.time[0]/1000
					this.form.cycle_end = this.form.time[1]/1000
				},
			},
			methods: {
				//编辑保存
				saveSubmit: function () {
					delete this.form.time;
					//提交表单验证
					this.$refs.form.validate((valid) => {
						if (valid) {
							this.$confirm('确认创建吗？', '提示', {}).then(() => {
								this.Loading = true;
								let para = Object.assign({}, this.form);
								createOrderByHand(para).then((res) => {
									this.Loading = false;
									if(res.code==1){
										this.$message.success(res.msg);
										this.goBack();
									}else {
										this.$message.error(res.msg);
									}
								});
							});
						}
					});
				},
			},
			mounted() {
			}
		}

	</script>
	<style scoped>
	.flex{
		display: flex;
	}
	.flex1{
		flex: 1;
		
	}
	.bigImg{
		display: flex;
		justify-content: center;
		align-content: center;
	}
	.content-main{
		width: 600px;
	}
	.avatar {
		width: 160px;
		height: 160px;
		margin-right: 10px;
		border-radius: 8px;
		display: flex;
		justify-content: center;
		align-content: center;
		overflow: hidden;
		background-color: #fefefe;
		border: 1px dashed #cccccc;
	}
	.avatar img{
		width: auto;
		height: 100%;
	}
	.avatar1{
		width: 90%;
		height: auto;
		margin: 600px auto 0;
	}
	</style>
