export const pickerOptions = {
    shortcuts: [{
        text: '最近一周',
        onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit('pick', [start, end]);
        }
    }, {
        text: '最近一个月',
        onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit('pick', [start, end]);
        }
    }, {
        text: '最近三个月',
        onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit('pick', [start, end]);
        }
    }]
}

export const pickerOptions1 = {
    disabledDate(time) {
        return time.getTime() > Date.now();
    },
    shortcuts: [{
        text: '最近一周',
        onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit('pick', [start, end]);
        }
    }, {
        text: '最近一个月',
        onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit('pick', [start, end]);
        }
    }, {
        text: '最近三个月',
        onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit('pick', [start, end]);
        }
    }]
}
//订单支付状态
export const orderStatus = [
    {
        value:'不使用',
        label: 0,
    },{
        value:'待支付',
        label: 1,
    },{
        value:'已支付',
        label: 2,
    },{
        value:'试用支付',
        label: 3,
    },{
        value:'超时未支付(已取消)',
        label: 4,
    }    
]
//支付类型
export const payType = [
    {
        value:'微信',
        label: 1,
    },{
        value:'支付宝',
        label: 2,
    },{
        value:'对公',
        label: 3,
    },{
        value:'试用支付',
        label: 4,
    }    
]
export const payStatus = [
    {
        value:'未付款',
        label: 1,
    },{
        value:'付款成功',
        label: 2,
    },{
        value:'已退款',
        label: 3,
    }
]
// 服务类型
export const serverType = [
    {
        value:'标准版',
        label: 10,
    },{
        value:'升级版',
        label: 11,
    },{
        value:'高级版',
        label: 12,
    }
]
export const versionList = [
    {
        value:'标准-3个月（首次）',
        label: '11',
    },{
        value:'标准-12个月（首次）',
        label: '12',
    },{
        value:'高级-3个月（首次）',
        label: '13',
    },{
        value:'高级-12个月（首次）',
        label: '14',
    },{
        value:'标准-3个月（续）',
        label: '21',
    },{
        value:'标准-12个月（续）',
        label: '22',
    },{
        value:'高级-3个月（续）',
        label: '23',
    },{
        value:'高级-12个月（续）',
        label: '24',
    }
]
